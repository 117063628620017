<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item" label="车牌号">
				<el-input class="el_input" v-model="form.plate_num" placeholder="车牌号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="发到时间">
			<div class="block">
				<el-date-picker
					v-model="insure_time_arr"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
			</div>
		</el-form-item>
		<el-form-item class="el_form_item">
		</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">搜索</el-button>
			</el-form-item>
		</el-form>
		<div id="container"  style="width:100%;height:1000px" ></div>
		<!-- 分页 -->
		<!-- <el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 50, 100, 200]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination> -->


	</div>
</template>

<script>
import { Loading } from 'element-ui';
	export default {
		components:{
		},
		data() {
			return {
				form:{
					plate_num:'', //车牌号
					start_time:'',//开始时间
					end_time:'',//结束时间
				},
				insure_time_arr:[],
				pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
			}
		},
		created() {
			this.init()
		},
		methods: {
			//切换
			trajectory_type(n){
				let loadingInstance = Loading.service(this.options);
					this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			page_ser(){
				this.init()
				if (this.insure_time_arr) {
					this.form.start_time = parseInt(this.insure_time_arr[0] / 1000);
					this.form.end_time = parseInt(this.insure_time_arr[1] / 1000);
				} else {
					this.form.start_time = "";
					this.form.end_time = "";
				}
				console.log(this.form)
				//读取轨迹
				this.get_point_list({
					start_time:this.form.start_time,
					end_time:this.form.end_time,
					plate_num:this.form.plate_num,
				});
			},
			//读取轨迹
			get_point_list(para){
				Loading.service(this.options);
				//http://192.168.1.252:8080/#/pages/transit_real_time/ser_trajectory
				//晋C33298
				//调接口
				this.$my.net.req({
					take_over_control:1,
					api:'https://public.jungong56.com',
					data:{
						mod:'order',
						ctr:'truck_track_info',
						plate_num:para.plate_num,
						start_time:para.start_time,
						end_time:para.end_time,
						truck_tord_num:this.truck_tord_num,
					},
					callback:(res)=>{
						if(res.code !=0){
							this.$my.other.msg({
								type:'info',
								str:'无轨迹'
							});
							let loadingInstance = Loading.service(this.options);
								this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
								loadingInstance.close();
							});
							return;
						}

						let data = res.msg
						if(data.list.length==0){
							this.$my.other.msg({
								type:'warning',
								str:'未读取到轨迹'
							});
							let loadingInstance = Loading.service(this.options);
								this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
								loadingInstance.close();
							});
							return;
						}
						
						// data.list.unshift(this.case_lngLnt)
						// data.list.push(this.aim_lngLnt)
						//取出地图对象
						let AMap = this.AMap;

						//地图中间点
						let map_center=data.list[parseInt(data.list.length/2)]
						// let map_center=data.list[parseInt(data.list.length)]
						if(map_center){
							this.map.setCenter(new AMap.LngLat(map_center.lon,map_center.lat)); 
						}
						
						//标记列表
						let marker_list=[];
						// 创建一个起点 Icon
						var startIcon = new AMap.Icon({
							// 图标尺寸
							size: new AMap.Size(25, 34),
							// 图标的取图地址
							image:
							"//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png",
							// 图标所用图片大小
							imageSize: new AMap.Size(135, 40),
							// 图标取图偏移量
							imageOffset: new AMap.Pixel(-9, -3),
						});
						// 创建一个终点 icon
						var endIcon = new AMap.Icon({
							size: new AMap.Size(25, 34),
							image:
							"//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png",
							imageSize: new AMap.Size(135, 40),
							imageOffset: new AMap.Pixel(-95, -3),
						});
						//设置终点
						let last_point=data.list[data.list.length-1];
						marker_list.push(new AMap.Marker({
							position:new AMap.LngLat(last_point.lon,last_point.lat),
							label:{
								content:`终点`, //设置文本标注内容
								direction:'right', //设置文本标注方位
							},
							icon: endIcon,
						}))
						//设置开始点
						marker_list.push(new AMap.Marker({
							position:new AMap.LngLat(data.list[0].lon,data.list[0].lat),
							label:{
								content:`起点`, //设置文本标注内容
								direction: 'right' //设置文本标注方位
							},
							icon: startIcon,
						}))
						//线列表
						let path=[];
						let every_length=''
						let trajectory_length=''
						if(data.list.length>10){
							every_length=parseInt(data.list.length/10)
						}else{
							every_length=parseInt(data.list.length)
						}
					    if(data.list.length>10){
							trajectory_length=parseInt(data.list.length/20)
						}else{
							trajectory_length=parseInt(data.list.length)
						}
						
						
					    let that = this
						// let trajectory_list = []
						data.list.forEach((item,index) => {
							//置入线数据
							path.push(new AMap.LngLat(item.lon,item.lat));
							//中间点
							if(index%every_length==0){
								var center_point=item;
								marker_list.push(new AMap.Marker({
									position:new AMap.LngLat(center_point.lon,center_point.lat),
									label:{
										content:center_point.date?center_point.date:that.$my.other.totime(center_point.timestamp), //设置文本标注内容
										direction:'top' //设置文本标注方位
									},
								}));
							}
							// if(index%trajectory_length==0){
							// 	trajectory_list.push(item)
							// }
						});
						//置入点
						this.map.add(marker_list);
						
						this.trajectory_type(0)
						//创建并置入线
						this.map.add(new AMap.Polyline({
							path: path,
							borderWeight:2,
							strokeColor: "#47aef3",
							strokeOpacity: 1,
							strokeWeight: 7,
							lineJoin: 'round',
							lineCap: 'round',
							showDir: true,
						})
						);
					}
				});
			},
			init(){
				//地图高度
				this.map_height=document.documentElement.clientHeight*0.8;
				window.onresize = () => {
					this.map_height=document.documentElement.clientHeight*0.8;
				}

				//加载地图
				this.$amap.load({
					plugins:[
						'AMap.DistrictSearch',
					]
				}).then((AMap)=>{

					//
					this.AMap=AMap;

					//地图初始化
					this.map=new AMap.Map("container",{

						resizeEnable: true,

						//初始化地图级别
						zoom:10,
					});

					//读取轨迹
					// this.get_point_list({
					// 	start_time:this.start_time,
					// 	end_time:this.end_time,
					// 	truck_plate_num:this.truck_plate_num,
					// });
				})
			},
			//获取货源列表
			get_page_data(){

			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: 100%;
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height: 700px;
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>